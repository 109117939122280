import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FullSurvey, IBlockInfo, IRejectValue, SessionQuestions, SessionResult, } from 'models/survey';
import { Api } from '../../../api/quoltricsApi';
import SurveyApi from '../../../api/surveyApi';
import { saveInLocalStorage, getFromLocalStorage } from 'utils/common';
import { FormikValues } from 'formik';
import errorHandler from '../../../utils/errorHandler';
import { ISurveyId, submitSurvey, ISubmitSurvey, submitSession, surveyResult, IResultSurvey } from '../../../api/surveyApi/surveyApi.type';

const surveyApi = new SurveyApi();

interface SurveyState {
    survey: FullSurvey | null;
    surveyId: string | null;
    sessionId: string | null;
    questions: SessionQuestions[];
}

const initialState: SurveyState = {
    survey: null,
    surveyId: null,
    sessionId: null,
    questions: [],
};

export const getSurveyId = createAsyncThunk<ISurveyId, void, IRejectValue>(
    'survey/getSurveyId',
    async (arg, { rejectWithValue }) => {
        try {
            const publicCode = getFromLocalStorage('publicCode');
            return await surveyApi.getSurveyId(publicCode);
        } catch (e) {
            return rejectWithValue(errorHandler('Get Survey Id', e));
        }
    }
);

export const submitSurveyCompletion = createAsyncThunk<ISubmitSurvey, submitSurvey, IRejectValue>(
    'survey/submitSurveyCompletion',
    async ({ qualtricsId, publicCode }, { rejectWithValue }) => {
        try {
            return await surveyApi.submitSurveyCompletion({ qualtricsId, publicCode });
        } catch (e) {
            return rejectWithValue(errorHandler('Get Survey Id', e));
        }
    }
);


export const submitSessionResult = createAsyncThunk<ISubmitSurvey, submitSession, IRejectValue>(
    'survey/AddSessionScore',
    async (submitSession, { rejectWithValue }) => {
        try {
            return await surveyApi.submitSessionResult(submitSession);
        } catch (e) {
            return rejectWithValue(errorHandler('Get Survey Id', e));
        }
    }
);

export const getSessionResult = createAsyncThunk<IResultSurvey, surveyResult, IRejectValue>(
    'survey/GetSurveyScore',
    async (surveyResult, { rejectWithValue }) => {
        try {
            return await surveyApi.getSessionResult(surveyResult);
        } catch (e) {
            return rejectWithValue(errorHandler('Get Survey Id', e));
        }
    }
);

export const fetchSurvey = createAsyncThunk<FullSurvey, string, IRejectValue>(
    'survey/fetchSurvey',
    async (id: string, { rejectWithValue }) => {
        try {
            const { result } = await Api.getSurvey(id);
            return result;
        } catch (e) {
            return rejectWithValue(errorHandler('Fetch Session', e));
        }
    }
);

export const startSession = createAsyncThunk<SessionResult, string>(
    'survey/startSession',
    async (id: string,) => {
        const { result, meta } = await Api.startSession(id);
        if (result && meta.httpStatus === '201 - Created') {
            return result;
        }else{
            throw new Error('Something went wrong');
        }
    }
);

export const updateSession = createAsyncThunk<SessionResult, { surveyId: string, sessionId: string, data: FormikValues }, IRejectValue>(
    'survey/updateSession',
    async ({ surveyId, sessionId, data }, { rejectWithValue }) => {

        try {
            const { result } = await Api.updateSession(surveyId, sessionId, data);
            return result;
        } catch (e) {
            return rejectWithValue(errorHandler('Update Session', e));
        }
    }
);

export const getResponse = createAsyncThunk<any, { surveyId: string, }, IRejectValue>(
    'survey/updateSession',
    async ({ surveyId }, { rejectWithValue }) => {
        try {
            const result = await Api.getResponse(surveyId);
            return result;
        } catch (e) {
            return rejectWithValue(errorHandler('Update Session', e));
        }
    }
);
export const getSurveyResult = createAsyncThunk<any, { surveyId: string, responseId: string, }, IRejectValue>(
    'survey/updateSession',
    async ({ surveyId, responseId }, { rejectWithValue }) => {
        try {
            const result = await Api.getSurveyResult(surveyId, responseId);
            return result;
        } catch (e) {
            return rejectWithValue(errorHandler('Update Session', e));
        }
    }
);

export const getCurrentSession = createAsyncThunk<SessionResult, { surveyId: string, sessionId: string }, IRejectValue>(
    'survey/getCurrentSession',
    async ({ surveyId, sessionId }, { rejectWithValue }) => {

        try {
            const { result } = await Api.getCurrentSession(surveyId, sessionId);
            return result;
        } catch (e) {
            return rejectWithValue(errorHandler('Update Session', e));
        }
    }
);

export const slice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        setSurvey: (state, { payload }: PayloadAction<FullSurvey>) => {
            state.survey = payload;
            saveInLocalStorage('survey', payload);
        },

        setSurveyId: (state, { payload }: PayloadAction<string>) => {
            state.surveyId = payload;
            saveInLocalStorage('surveyId', payload);
        },

        setQuestions: (state, { payload }: PayloadAction<SessionQuestions[]>) => {
            const surveys = getFromLocalStorage('survey');
            if (surveys) {
                for (let index = 0; index < payload.length; index++) {
                    payload[index].validation = surveys.questions[payload[index].questionId]?.validation ? surveys.questions[payload[index].questionId].validation : { doesForceResponse: false };
                }
            }

            state.questions = payload;
            saveInLocalStorage('questions', payload);
        },

        setSessionId: (state, { payload }: PayloadAction<string>) => {
            state.sessionId = payload;
            saveInLocalStorage('sessionId', payload);
        },
    },

    extraReducers: builder => {

        builder.addCase(fetchSurvey.fulfilled, (state, { payload }) => {
            state.survey = payload;
            const flowArray = Object.entries(payload.flow).map(([key]) => key);
            const blocksInOrder = Object.entries<IBlockInfo>(payload.blocks)
                .sort((a, b) => (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    flowArray.indexOf(a) - flowArray.indexOf(b)
                ));

            state.survey.blocks = Object.fromEntries(blocksInOrder);
            saveInLocalStorage('survey', payload);
        });

        builder.addCase(startSession.fulfilled, (state, { payload }) => {
            const surveys = getFromLocalStorage('survey');
            if (surveys)
                for (let index = 0; index < payload.questions.length; index++) {
                    payload.questions[index].validation = surveys.questions[payload.questions[index].questionId].validation;
                }
            state.sessionId = payload.sessionId;
            state.questions = payload.questions;
            saveInLocalStorage('sessionId', payload.sessionId);
            saveInLocalStorage('questions', payload.questions);
        });

        builder.addCase(updateSession.fulfilled, (state, { payload }) => {
            state.sessionId = payload.sessionId;
            saveInLocalStorage('sessionId', payload.sessionId);
        });

        builder.addCase(getSurveyId.fulfilled, (state, { payload }) => {
            state.surveyId = payload.data.qualtricsId;
            saveInLocalStorage('surveyId', payload.data.qualtricsId);
        });
    }

});

export const {
    setSurvey,
    setQuestions,
    setSessionId,
    setSurveyId
} = slice.actions;

export default slice.reducer;
