import {ISurveyId,submitSurvey,ISubmitSurvey, submitSession, surveyResult, IResultSurvey} from './surveyApi.type';
import BaseApi from '../index';

export default class SurveyApi extends BaseApi {

    public getSurveyId(publicCode:string): Promise<ISurveyId> {
        return this.baseApi.post(`/Survey/Get?publicCode=${publicCode}`).then(({ data }) => data);
    }

    public submitSurveyCompletion(data:submitSurvey): Promise<ISubmitSurvey> {
        return this.baseApi.post(`/Survey/SaveRecord`,data).then(({ data }) => data);
    }

    public submitSessionResult(data:submitSession): Promise<ISubmitSurvey> {
        return this.baseApi.post(`/Survey/AddSessionScore`,data).then(({ data }) => data);
    }

    public getSessionResult(data:surveyResult): Promise<IResultSurvey> {
        return this.baseApi.post(`/Survey/GetSurveyScore`,data).then(({ data }) => data);
    }
}
