import React from 'react';
import styles from './LeaveSurveyModal.module.scss';
import {Button} from 'components';
import Paths from '../../consts/Paths';
import {md} from '../../consts/Breakpoints';
import useWindowSize from '../../hooks/useWindowSize';
import Close from '../../assets/icons/Close';
import {black} from '../../consts/Colors';
import {useAppSelector} from '../../redux/hooks';
import {selectContent} from '../../redux/slices/contactInfo/selectors';
import {EContentKeys} from '../../consts/ContentKeys';

const illustration = `${process.env.REACT_APP_CLOUDFRONT_URI}/Illustrations/ExitModal/exit-modal.png`;
const illustrationSm = `${process.env.REACT_APP_CLOUDFRONT_URI}/Illustrations/ExitModal/Small_illustration_3.png`;

interface IModalProps {
    closeModal: () => void;
}

const Modal: React.FC<IModalProps>= ({closeModal}) => {
    const pathname = window.location.pathname;
    const {width} = useWindowSize();
    const pageContent = useAppSelector(selectContent)[EContentKeys.LEAVE_PAGE_KEY];
    const onLeaveHandler = () => {
        closeModal();
        localStorage.clear();
    };

    return (
        <div className={styles.modal}>
            <img alt="figure" src={width > md ? illustration : illustrationSm} className={styles.illustration} />
            <div className={styles.wrapperContent}>
                <h4 className="title mb-20 ">{pageContent.Title}</h4>
                <p className="description-sm mb-30">
                    {pathname === Paths.contactInfo ?
                        'By continuing you aren\'t participating in the study.'
                        :
                        pageContent.Subtitle
                    }
                </p>
                <div className={styles.actionWrapper}>
                    <div className='mr-20'>
                        <Button className={styles.leaveBtn} onClick={onLeaveHandler} href={Paths.aboutPage}>Leave</Button>
                    </div>
                    <Button onClick={closeModal}>Continue</Button>
                </div>
            </div>
            <div className={styles.close} onClick={closeModal}><Close fill={black}/></div>
        </div>
    );
};

export default Modal;